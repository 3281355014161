import store from '@/store';
import unitTypeService from '../services/unit-type.service';
import { UnitTypeSubgroup } from '../../UnitTypeSubgroups/entities/unit-type-subgroup.entity';

export class UnitType {
  public id: string = '';

  public name: string = '';

  public active: boolean | number = false;

  public company_id: string | undefined = '';

  public company: any;

  public has_relationship: boolean | number = false;

  public subgroups: UnitTypeSubgroup[];

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name || '';
    this.company_id = data.company && data.company.id ? data.company.id : store.getters.user.company_id;
    this.company = data.company;
    this.subgroups = data.subgroups
      ? data.subgroups.map((it: any) => new UnitTypeSubgroup(it))
      : [];
    this.active = data.active === false ? 0 : 1;
    this.has_relationship = data.has_relationship === true;
  }

  public get activeText() {
    return this.active ? 'Ativo' : 'Inativo';
  }

  private format() {
    return {
      ...this,
      subgroups: this.getArraySubgroups(),
    };
  }

  private getArraySubgroups() {
    return this.subgroups.length ? this.subgroups.map(it => it.id) : undefined;
  }

  public save() {
    return unitTypeService.create(this.format());
  }

  public update() {
    return unitTypeService.update(this.format());
  }

  public delete() {
    return unitTypeService.delete(this.id);
  }
}
