
import { Component, Vue } from 'vue-property-decorator';
import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import { UnitType } from '../../entities/unit-type.entity';
import unitTypeService from '../../services/unit-type.service';

@Component({
  components: {
    SimplePagination,
  },
})
export default class UnitTypeListComponent extends Vue {
  private unitTypes: UnitType[] = [];

  private get headers(): any {
    const items = [
      { text: 'Nome', value: 'name', width: '70%' },
      {
        text: 'Ativo',
        value: 'activeText',
      },
      {
        text: 'Ações',
        align: 'center',
        value: 'actions',
      },
    ];

    if (this.$permissionAccess.group.isSuperAdmin) {
      items.splice(1, 0, { text: 'Empresa', value: 'company.name', width: '20%' });
    }
    return items;
  }

  private goToFormEdit(unitType: UnitType) {
    this.$router.push({
      name: 'unit-type-edit',
      params: {
        id: unitType.id,
      },
    });
  }

  private goToFormCreate() {
    this.$router.push({
      name: 'unit-type-create',
    });
  }

  private async getunitTypes() {
    const unitTypes = await unitTypeService.getAllUnitTypesPaginate();
    this.unitTypes = unitTypes.map((unitType: UnitType) => new UnitType(unitType));
  }

  public async backPagination() {
    if (!unitTypeService.simplePaginationService.prevLink) return;
    const unitTypes = await unitTypeService.simplePaginationService.prev();
    this.unitTypes = unitTypes.map((unitType: UnitType) => new UnitType(unitType));
  }

  public async nextPagination() {
    if (!unitTypeService.simplePaginationService.nextLink) return;
    const unitTypes = await unitTypeService.simplePaginationService.next();
    this.unitTypes = unitTypes.map((unitType: UnitType) => new UnitType(unitType));
  }

  public async goToFirst() {
    if (!unitTypeService.simplePaginationService.firstLink) return;
    const unitTypes = await unitTypeService.simplePaginationService.goToFirst();
    this.unitTypes = unitTypes.map((unitType: UnitType) => new UnitType(unitType));
  }

  private async created() {
    this.getunitTypes();
  }
}
