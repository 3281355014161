import { AxiosResponse } from 'axios';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import http from '@/services/http.service';
import { UnitType } from '../entities/unit-type.entity';

class UnitTypeService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public create(payload: UnitType) {
    return http.post('/unit-type', payload).then(({ data }: AxiosResponse) => data);
  }

  public update(payload: UnitType) {
    return http.put(`/unit-type/${payload.id}`, payload).then(({ data }: AxiosResponse) => data);
  }

  public delete(unitTypeId: string) {
    return http.delete(`/unit-type/${unitTypeId}`).then(({ data }: AxiosResponse) => data);
  }

  public getAllUnitTypes() {
    return http.get('/unit-type').then(({ data }: AxiosResponse) => data.data);
  }

  public getAllUnitTypesPaginate() {
    return http
      .get('/unit-type', {
        params: {
          paginate: 1,
        },
      })
      .then(({ data }: AxiosResponse) => {
        this.simplePaginationService.setPaginationLinks(data.links);
        return data.data;
      });
  }

  public getUnitTypeById(id: string) {
    return http.get(`/unit-type/${id}`).then(({ data }: AxiosResponse) => data.data);
  }
}

const simplePaginationService = new SimplePagination();
export default new UnitTypeService(simplePaginationService);
